// import jQuery from 'jquery'
import axios from "axios";
import {
    // eslint-disable-next-line no-unused-vars
    Dialog,
    Toast
} from "vant";
// import qs from 'qs';
// 时间转换
/**
 * 日期格式化
 * @param date  传入的时间
 * @param fmt   格式化样式
 * @returns {string}  返回格式化之后的时间
 */
export function formatDate(date, fmt) {
    if (!fmt) {
        fmt = 'yyyy-MM-dd'
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    }
    let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + "";
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1 ? str : padLeftZero(str)
            );
        }
    }
    return fmt;
}

function padLeftZero(str) {
    return ("00" + str).substr(str.length);
}
// 身份证号显示*
export function fn(idcard) {
    return idcard.replace(/^(.{4})(?:\d+)(.{4})$/, "$1**********$2");
}
//姓名脱敏
export function formatName(str) {
    return str.substr(0, 1) + new Array(str.length).join("*");
}
//手机号脱敏
export function formatPhone(phone) {
    return phone.replace(/^(.{3})(?:\d+)(.{4})$/, "$1****$2");
}
// base64  to blob二进制
export function dataURItoBlob(dataURI) {
    var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0]; // mime类型
    var byteString = atob(dataURI.split(",")[1]); //base64 解码
    var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
    var intArray = new Uint8Array(arrayBuffer); //创建视图

    for (var i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([intArray], {
        type: mimeString
    });
}
// oss直传图片
export const updateFileToOSS = (sing, file) => {
    const formData = new FormData();
    formData.append("key", sing.dir + file.name);
    formData.append("OSSAccessKeyId", sing.accessid);
    formData.append("policy", sing.policy);
    formData.append("signature", sing.signature);
    formData.append("success_action_status", "200");
    formData.append("x:tTest", "test");
    formData.append("file", file); // 一定在最后
    return new Promise((resolve, reject) => {
        axios({
                // processData: false,
                // contentType: false,
                method: "post",
                dataType: "json",
                url: sing.host, // 设置代理了
                headers: {
                    //"Content-Type": "multipart/form-data",
                },
                data: formData
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
//获取默认就诊人
export function getPatient() {
    return new Promise((resolve, reject) => {
        axios
            .post("v1/userInfo/bindUserinfo", {})
            .then((response) => {
                resolve(response);
                return response;
            })
            .catch((error) => {
                reject(error);
            });
    });
}
//oss直传图片视频等
export function uploadOss() {
    return new Promise((resolve, reject) => {
        axios
            .post("v1/UpLoad/getToken", {})
            .then((response) => {
                resolve(response);
                return response;
            })
            .catch((error) => {
                reject(error);
            });
    });
}
//获取当前时间  getDay(0, 3600000), 第一个数传1为当前日期加1天 返回 2021-11-13
export function getDay(day, hours) {
    var today = new Date()
    var targetday = today.getTime() + 1000 * 60 * 60 * 24 * day + hours
    today.setTime(targetday)
    var tYear = today.getFullYear()
    var tMonth = today.getMonth()
    var tDate = today.getDate()
    // var getHours = today.getHours()
    tMonth = doHandleMonth(tMonth + 1)
    tDate = doHandleMonth(tDate)
    return tYear + '-' + tMonth + '-' + tDate
}
//获取当前周几  getDay(0, 3600000), 第一个数传1为当前日期加1天 返回 周几
export function getWeek(day, hours) {
    var today = new Date()
    var targetday = today.getTime() + 1000 * 60 * 60 * 24 * day + hours
    today.setTime(targetday)
    var week = today.getDay()
    switch (week) {
        case 1:
            week = '周一';
            break;
        case 2:
            week = '周二';
            break;
        case 3:
            week = '周三';
            break;
        case 4:
            week = '周四';
            break;
        case 5:
            week = '周五';
            break;
        case 6:
            week = '周六';
            break;
        case 0:
            week = '周日';
            break;
    }
    return week
}

function doHandleMonth(month) {
    var m = month
    if (month.toString().length === 1) {
        m = '0' + month
    }
    return m
}
//根据身份证号获取年龄
export function queryage(idc) { //得到年龄
    var brthday = idc.substring(6, 10) + "-" + idc.substring(10, 12) + "-" + idc.substring(12, 14);
    let age = getAge(brthday);
    return age
}
// 判断用户的年龄
function getAge(birthday) {
    let birthdays = new Date(birthday.replace(/-/g, '/'));
    let d = new Date();
    let age =
        d.getFullYear() -
        birthdays.getFullYear() -
        (d.getMonth() < birthdays.getMonth() ||
            (d.getMonth() == birthdays.getMonth() &&
                d.getDate() < birthdays.getDate()) ? 1 : 0);
    return age;
}

/**
 *获取几个月前的输入日期
 *{param:DateTime} date 输入日期(YYYY-MM-DD)
 *{param:number } monthNum 月数
 */
export function GetPreMonthDay(date, monthNum) {
    var dateArr = date.split('/');
    var year = dateArr[0]; //获取当前日期的年份
    var month = dateArr[1]; //获取当前日期的月份
    var day = dateArr[2]; //获取当前日期的日
    // eslint-disable-next-line no-unused-vars
    var days = new Date(year, month, 0);
    days = days.getDate(); //获取当前日期中月的天数
    var year2 = year;
    var month2 = parseInt(month) - monthNum;
    if (month2 <= 0) {
        var absM = Math.abs(month2);
        year2 = parseInt(year2) - Math.ceil(absM / 12 == 0 ? 1 : parseInt(absM) / 12);
        month2 = 12 - (absM % 12);
    }
    var day2 = day;
    var days2 = new Date(year2, month2, 0);
    days2 = days2.getDate();
    if (day2 > days2) {
        day2 = days2;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }
    var t2 = year2 + '/' + month2 + '/' + day2;
    return t2;
}

//功能未开放
export function menuOpen() {
    Toast({
        message: '功能建设中',
        icon: 'smile-o',
        duration: '800'
    });
}
//倒计时
// eslint-disable-next-line no-unused-vars
export function countDown(endTime, i) {
    var time_start = new Date().getTime(); //设定当前时间
    var time_end = new Date(endTime).getTime(); //设定目标时间
    // 计算时间差
    var time_distance = time_end - time_start;
    if (time_distance < 0) {
        var result = "0分";
    } else {
        // 天
        var int_day = Math.floor(time_distance / 86400000);
        time_distance -= int_day * 86400000;
        // 时
        var int_hour = Math.floor(time_distance / 3600000);
        time_distance -= int_hour * 3600000;
        // 分
        var int_minute = Math.floor(time_distance / 60000);
        time_distance -= int_minute * 60000;
        // 秒
        var int_second = Math.floor(time_distance / 1000);
        // 时分秒为单数时、前面加零

        if (int_day < 10 && int_day > 0) {
            int_day = "0" + int_day;
        }
        if (int_hour > 0 && int_hour < 10) {
            int_hour = "0" + int_hour;
        }
        if (int_minute < 10) {
            int_minute = "0" + int_minute;
        }
        if (int_second < 10) {
            int_second = "0" + int_second;
        }
        // if (int_day == 0 && int_hour == 0 && int_minute == 0 && int_second == 0) {
        //   result = "0"; //日期结束
        // }
        // 显示时间
        if (int_day != 0) {
            result = int_day + '天' + int_hour + "小时" + int_minute + "分" + int_second + "秒";
            // console.log(result)
        } else {
            if (int_hour == "0" && int_minute == "0") {
                result = int_second + "秒";
            } else if (int_hour == "0") {
                result = int_minute + "分" + int_second + "秒";
            } else {
                result = int_hour + "小时" + int_minute + "分" + int_second + "秒";
            }
        }

    }

    return result
    // 设置定时器
    // setTimeout("countDown('"+ endTime + "','" + i +"')",1000); // 这里也可以用setTimeout 在函数体内实现循环

}

// js字符串转日期Date
// 字符串格式：2020-10-13 12:00:01 当前时间加15分钟
export function stringToDate(strDate) {
    var tempStrs = strDate.split(" ");

    var dateStrs = tempStrs[0].split("-");
    var year = parseInt(dateStrs[0], 10);
    var month = parseInt(dateStrs[1], 10) - 1;
    var day = parseInt(dateStrs[2], 10);

    var timeStrs = tempStrs[1].split(":");
    var hour = parseInt(timeStrs[0], 10);
    var minute = parseInt(timeStrs[1], 10);
    var second = parseInt(timeStrs[2], 10);

    var date = new Date(year, month, day, hour, minute, second);
    return date;
}

// js日期Date格式化为字符串
// 字符串格式：2020-10-13 12:00:01
export function format(date, fmt) {
    var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length == 1 ?
                o[k] :
                ("00" + o[k]).substr(("" + o[k]).length)
            );
        }
    }
    return fmt;
}