<template>
  <div class="main">
    <div class="registrationSuccess">
      <div class="succeed">
        <p>提交成功！</p>
        <p>-CONGRATULATION</p>
      </div>
    </div>
    <div class="info">
      <div class="top">
        注册信息
        <div class="borderBottom"></div>
      </div>
      <div class="detail first">
        <div class="detailName">姓名</div>
        <div class="detailInfo">{{ userData.name }}</div>
      </div>
      <div class="detail">
        <div class="detailName">身份证号</div>
        <div class="detailInfo">{{ userData.id_card }}</div>
      </div>
      <div class="detail">
        <div class="detailName">操作员号</div>
        <div class="detailInfo">{{ userData.oper_no }}</div>
      </div>

      <div class="detail">
        <div class="detailName">角色</div>
        <div class="detailInfo">{{ userData.role_name }}</div>
      </div>
      <div class="detail">
        <div class="detailName">手机号码</div>
        <div class="detailInfo">{{ userData.phone }}</div>
      </div>
      <div class="detail">
        <div class="detailName">医院</div>
           <div class="detailInfo hospital" v-if="userData.hos_name">
            <p >{{userData.hos_name}}</p>

        </div>
        <div class="detailInfo hospital" v-else>
                   <p v-for="(item, i) in userData.hos" :key="i">{{ item.hos_name }}</p>
        </div>
      </div>
    </div>

    <router-link :to="{ path: '/home' }">
      <van-button round type="info">完成</van-button>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userData: {},
      hos: [],
    };
  },
  created() {
    if (this.$route.query.page == 1) {
      this.registerSucc();
    } else if (this.$route.query.page == 2) {
      this.getAdminAuditDetail();
    }
  },
  methods: {
    getAdminAuditDetail() {
      var that = this;
      console.log(that.$route.query.id);
      that.$http
        .post("v1/userInfo/getMyDetail", {
          user_info_id: that.$route.query.id,
        })
        .then((res) => {
          console.log(res);
          that.userData = res.data.data;
         
        });
    },
    registerSucc() {
      var that = this;
      that.$http
        .post("v1/userInfo/registerSucc", that.$route.query.userData)
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res);
            that.userData = res.data.data;
          } else {
            console.log(res.data.message);
          }
        });
    },
  },
};
</script>
<style  lang="less" scoped>
.main {
  height: 100%;
  width: 100%;
  background-color: white;
}
.succeed {
  position: absolute;
  top: 59px;
  left: 168px;
  color: #ffffff;
  font-size: 14px;
  z-index: 10;
}
.succeed > p:nth-child(1) {
  font-size: 23px;
  margin: 0;
  padding: 0;
}
.succeed > p {
  margin: 0;
  padding: 0;
}

.registrationSuccess {
  height: 165px;
  width: 100%;
  font-size: 20px;
  background-image: url(../assets/images/bg_succeed.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position-y: 88%;
}

.info {
  background-color: white;
  border-radius: 10px 10px 0 0;
  position: absolute;
  left: 0;
  top: 155px;
  right: 0;
  padding-top: 20px;
}
.top {
  text-align: center;
  font-size: 17px;
  padding-bottom: 15px;

  font-weight: 500;
  position: relative;
}
.borderBottom {
  position: absolute;
  bottom: 0;
  height: 1px;
  background: #bcbcbc;
  left: 20px;
  right: 20px;
}
.detail {
  display: flex;
  width: 100%;

  font-size: 14px;
  margin: 10px 0;
}
.first {
  margin-top: 20px;
}
.detailName {
  width: 35%;
  padding-left: 15%;
  font-weight: 500;
}
.detailInfo {
  width: 65%;
  color: #999999;
}
.hospital > p {
  margin-bottom: 10px;
  margin-top: 0;
  padding: 0;
}

.van-button {
  width: 80%;
  margin-left: 10%;
  position: absolute;
  bottom: 20px;
  font-size: 18px;
}
</style>