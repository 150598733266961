
 <template>
  <div class="nodata">
    <!-- <van-empty description="暂无数据" /> -->
    <van-empty
      class="custom-image"
      :image="require('../assets/images/nodata.png')"
      :description="description ? description : '暂无数据'"
    />
  </div>
</template>

<script>
export default {
  name: "nodata",
  components: {},
  props: {
    description: String,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.custom-image /deep/.van-empty__image {
  width: 130px;
  height: auto;
  margin-top: 30px;
}
</style>