<template>
  <div>
    <HeadBar @childFn="parentFn"></HeadBar>
    <div class="search">
      <div>
        <van-search
          v-model="value"
          show-action
          placeholder="请输入机器码"
          @search="onSearch"
        >
          <template #action>
            <div @click="onSearch">查询</div>
          </template>
        </van-search>
      </div>
    </div>
    <div class="content">
      <div class="individual" v-for="(item, index) in machineList" :key="index">
        <div class="individual_one">{{ item.MachineID }}</div>
        <div class="individual_two">位置：{{ item.Position }}</div>
        <div class="individual_three">
          <div @click="goMachineConfiguration(item.MachineID)">机器配置</div>
          <div @click="goModuleConfiguration(item.MachineID)">模块管理</div>
          <div @click="shutDown(item, 'GJ')">关闭计算机</div>
          <div @click="shutDown(item, 'CQ')">重启计算机</div>
        </div>
        <div class="line"></div>
        <div class="individual_last">
          <div>IP:{{ item.IPAddr }}</div>
          <div>MAC:{{ item.MacAddr }}</div>
        </div>
      </div>
    </div>
    <LoadingToast v-if="loading"></LoadingToast>
  </div>
</template>

<script>
import Storage from "../assets/js/tools";
import { mapState } from "vuex";
import HeadBar from "../components/HeadBar.vue";
import LoadingToast from "../components/loadingToaast.vue";
import httpUrl from "../config/httpUrl"; //引用公共
import { Toast } from "vant";
export default {
  components: {
    HeadBar,
    LoadingToast,
  },
  data() {
    return {
      value: "",
      machineList: [],
      loading: true,
      message: {},
      httpUrl: httpUrl,
      user_info_id:''
    };
  },
  computed: {
    ...mapState(["roleId"]),
  },
  methods: {
    onSearch() {
      this.getList();
    },
    //获得列表
    getList() {
      var that = this;
      that.machineList = [];
      that.loading = true;
      that.$http
        .post("v1/zzj", {
          url: "getMachine",
          user_id: that.$store.state.roleId,
          body: {
            MachineID: that.value,
          },
        })
        .then((res) => {
          // console.log(res);
          that.loading = false;
          console.log(JSON.parse(res.data));
          that.machineList = JSON.parse(res.data).MachineList;
        });
    },
    goMachineConfiguration(e) {
      this.$router.push({
        path: "/machineConfiguration",
        query: {
          machineId: e,
        },
      });
    },
    goModuleConfiguration(e) {
      this.$router.push({
        path: "/moduleConfiguration",
        query: {
          machineId: e,
        },
      });
    },
    //关机重启
    shutDown(e, cmd) {
      var that = this;
      that.$http
        .post("v1/zzj", {
          url: "ShutdownMachine",
          user_id: that.$store.state.roleId,
          body: {
            ip: e.IPAddr,
            cmd: cmd, //关机：GJ、重启：CQ、重启程序：GB
          },
        })
        .then((res) => {
          Toast(JSON.parse(res.data).Message);
          console.log(res);
        });
    },
    //人脸
    faceRecognition() {
      var that = this;
      console.log(window.location);
      //   alert(that.$store.state.roleId);
      this.$http
        .post("/v1/userInfo/H5FaceEncrypt", {
          user_info_id: that.user_info_id,
          url: that.httpUrl.vueDomain + "/#/machineList",
        })
        .then((res) => {
          // that.loadShow = false;
          if (res.data.code == 200) {
            that.loading = false;
            var hash = res.data.data.hash;
            var data = res.data.data.data;
            window.location.href =
              "http://face.jnkyzx.cn/system/faceMp/index?hash=" +
              hash +
              "&data=" +
              data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //接受头部子组件传值
    parentFn(e) {
      let storage = new Storage();
      this.user_info_id = e.id;
      if (this.$route.query.hash) {
        
        storage.setItem({
          name: "face",
          value: true,
          // expires: 86400000,//一天

           expires: 300000,//5分钟
        });
      }
      if (storage.getItem("face")) {
        this.getList();
      } else {
        this.faceRecognition();
      }
    },
  },
  // created() {
  // //  alert( this.$route.query.hash);

  // },
  watch: {
    roleId: {
        handler(newVal, oldVal) {
          if (oldVal != 0) {
               this.getList();
          }
         
        },
        // deep: true, // 是否开启深度监听
        // immediate: true, // 是否初始化时就执行一次
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(from);
    });
  },
};
</script>

<style  lang="less" scoped>
.search {
  height: 44px;
  width: 100%;
  color: white;
  margin-top: 50px;
}
.van-search {
  padding: 5px 12px;
}
/deep/.van-search .van-cell {
  padding: 3px 8px 3px 0;
  background-color: #f3f3f3;
}
.content {
  width: 100%;
  .individual {
    box-sizing: border-box;
    width: 94%;
    margin: 20px 3% 0 3%;
    background: white;
    border-radius: 6px;
    padding: 5px 12px;
    .individual_one {
      font-weight: bold;
      margin: 5px auto;
    }
    .individual_two {
      font-size: 14px;
      margin: 5px auto 7px auto;
    }
    .individual_three {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #459beb;
      // div{
      //     width: 25%;
      // }
      div:nth-last-child(1) {
        color: #ea5858;
      }
    }
    .line {
      margin: 5px auto;
      height: 1px;
      //   background: #c3c1c1;
      border-bottom: #c3c1c1 1px solid;
    }
    .individual_last {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #676565;
    }
  }
}
</style>