<template>
  <div>
    <van-form @submit="onSubmit">
      <!-- <van-field v-model="id" name="ID" label="ID" disabled /> -->
      <van-field
        v-model="machineId"
        name="机器编号"
        label="机器编号"
        disabled
      />
      <van-field
        v-model="ConfigExplain"
        name="配置名称"
        label="配置名称"
        @input="showList"
        @click-input="firstClick"
        @blur="leaveField"
      />
      <div class="search-content" ref="search" v-if="configList.length">
        <ul>
          <li
            class="search-item border-bottom"
            v-for="(item, index) of configList"
            :key="index"
            @click="changeExplain(item,index)"
          >
            {{ item }}
          </li>
          <!-- <li class="search-item border-bottom" >没有找到匹配数据</li> -->
        </ul>
      </div>
      <van-field v-model="ConfigName" name="字段名称" label="字段名称" />
      <van-field v-model="ConfigValue" name="参数" label="参数" />
      <van-field v-model="remark" name="备注" label="备注" type="textarea" />
      <div class="submit">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>


<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      machineId: this.$route.query.machineId,
      ConfigExplain: "",
      ConfigName: "",
      ConfigValue: "",
      remark: "",
      allLists:[],
      configList: [], //搜索得到的配置名称列表
      configLists: [], //全部配置名称（不变）
      timer: "",
      searchStop: false,
      chooce: false,
      first: true,
    };
  },

  methods: {
    onSubmit() {
      var that = this;
      that.chooce = false;

      if (!that.ConfigExplain) {
        Toast("请选择配置名称");
        return false;
      }
      that.configLists.forEach((e) => {
        if (e == that.ConfigExplain) {
          that.chooce = true;
        }
      });
      if (!that.chooce) {
        Toast("请选择正确的配置名称");
        return;
      }
      if (!that.ConfigName) {
        Toast("请填写字段名称");
        return false;
      }
      if (!that.ConfigValue) {
        Toast("请填写参数");
        return false;
      }
      //  var obj =  {"MachineID":that.MachineID,"ConfigExplain":"btnZDZMPrint","ConfigName":"(首页)诊断证明打印","ConfigValue":"1","Remark":"7"}
      var obj = {
        MachineID: that.machineId,
        ConfigExplain: that.ConfigExplain,
        ConfigName: that.ConfigName,
        ConfigValue: that.ConfigValue,
        Remark: that.remark,
      };
      //   console.log(JSON.stringify(obj));
      that.$http
        .post("v1/zzj", {
          url: "AddConfig",
          user_id: that.$store.state.roleId,
          body: {
            jsonStr: JSON.stringify(obj),
            // MachineID:that.machineId
          },
        })
        .then((res) => {
          if (JSON.parse(res.data).Code == 0) {
            Toast("添加成功");
            that.$router.go(-1);
          } else {
            Toast(JSON.parse(res.data).Message);
          }
        });
      //     .then((res) => {
      //       console.log(res);
      //       if (JSON.parse(res.data).Code == "0") {
      //         Toast.success("修改成功");
      //         that.$router.go(-1);
      //       } else {
      //         Toast.fail(JSON.parse(res.data).Message);
      //       }
      //     });
    },
    getConfigExplain() {
      var that = this;
      that.$http
        .post("v1/zzj", {
          url: "getAllConfig",
          user_id: that.$store.state.roleId,
        })
        .then((res) => {
          if (JSON.parse(res.data).Code == 0) {
            console.log(JSON.parse(res.data));
            var list = JSON.parse(res.data).MachineConfigList;
            that.allLists = list
            list.forEach((element) => {
              that.configLists.push(element.ConfigExplain);
            });
            console.log(that.configLists);
          }
        });
    },
    changeExplain(e,i) {
      this.ConfigExplain = e;
      this.ConfigName = this.allLists[i].ConfigName;
      this.ConfigValue =  this.allLists[i].ConfigValue;
      this.remark = this.allLists[i].Remark;
      this.configList = [];
    },
    //输入框变化显示
    showList(n) {
      this.searchStop = false;
      this.configLists.forEach((e) => {
        if (e == n) {
          this.configList = [];
          this.searchStop = ture;
          return;
        }
      });
      if (this.searchStop) {
        return false;
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (!this.ConfigExplain) {
        this.configList = this.configLists;
        return;
      }

      this.timer = setTimeout(() => {
        var result = [];
        this.configLists.forEach((element) => {
          if (element.indexOf(this.ConfigExplain) > -1) {
            result.push(element);
          }
        });
        console.log(result);
        this.configList = result;
      }, 100);
    },
    //
    firstClick() {
      if (this.first) {
        this.first = false;
        this.configList = this.configLists;
      }
    },
    leaveField() {
      this.first = true;
      setTimeout(() => {
         this.configList = [];
      }, 100);
      // this.configList = [];
    },
  },
  created() {
    this.getConfigExplain();
  },
  // watch: {
  //   ConfigExplain: {

  //     handler(n,o) {

  //     },
  //     // immediate: true,
  //   },
  // },
};
</script>


<style lang="less" scoped>
/deep/.van-field__control:disabled {
  color: #646566;
  -webkit-text-fill-color: #646566;
}
/deep/.van-field--disabled .van-field__label {
  color: #646566;
}
/deep/.van-picker {
  z-index: 20;
}
.search-content {
  width: 62%;
  /* padding-left: 7.575rem; */
  color: #646566;
  background: white;
  position: fixed;
  top: 88px;
  left: 7rem;
  z-index: 100;
  padding-left: 0.75rem;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1),0 2px 4px 1px rgba(0,0,0,.18);
  // border: gray solid 1px;
  max-height: 60vh;
  overflow-y: scroll;
  border-top: unset;
  border-left: unset;
  .search-item {
    // width: calc(100% - 7.575rem);
    height: 30px;
    border-bottom: #f8f8f8 1px solid;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.submit {
  margin: 5%;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 90%;
}
</style>