<template>
  <div>
    <div v-if="auditList.length > 0">
      <div class="main">
        <div>
          <ul v-for="(item, i) in auditList" :key="i">
            <li>
              <span style="font-size: 15px">{{ item.create_time }} </span>
              <span
                class="itemright"
                v-if="item.is_audit_status == '1'"
                style="color: #1154e7"
                >{{ item.is_audit }}</span
              >
              <span
                class="itemright"
                v-else-if="item.is_audit_status == '2'"
                style="color: #fc3333"
                >{{ item.is_audit }}</span
              >
              <span class="itemright" v-else style="color: #ff9a00">{{
                item.is_audit
              }}</span>
            </li>
            <li>
              <span>申请医院</span><span>{{ item.hos_name }}</span>
            </li>

            <li>
              <span>姓名</span><span>{{ item.name }}</span>
            </li>
            <li>
              <span>身份证号</span>
              <span>{{ item.id_card }} </span>
            </li>
            <li>
              <span>工号</span><span>{{ item.oper_no }}</span>
            </li>

            <li>
              <span>手机号</span><span>{{ item.phone }}</span>
            </li>

            <li>
              <van-button
                type="default"
                class="triage signIn"
                v-if="item.is_audit_status == 2 && item.is_admin !=1"
                @click="goEdit(item.is_audit_status, item.id)"
                >重新编辑
              </van-button>
            </li>
             <li>
              <van-button
                type="default"
                class="triage signIn"
                v-if="$store.state.administrator == 1 && item.is_audit_status !=1"
               @click="goNext(item.id)"
                >去审核
              </van-button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <van-empty v-else image="search" description="暂无信息" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      auditList: [],
      roleList: [],
    };
  },
  created() {
    this.registerRecord();
  },
  methods: {
    registerRecord() {
      var that = this;
      that.$http
        .post("v1/userInfo/registerRecord", {
          admin_id: that.$store.state.roleId,
        })
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res);
            that.auditList = res.data.data;
          }
        });
    },
    goEdit(e, a) {
      if (e == "1") {
        this.$router.push({
          path: "/complete",
          query: { userData: a, page: 2 },
        });
        console.log(a);
      } else {
        this.$router.push({ path: "/registerInfo", query: { id: a } });
      }
    },
      goNext(e) {
      this.$router.push({path: "/registerAudit", query: {userid: e}});
    },
  },
};
</script>
<style  lang="less" scoped>
.audit {
  display: flex;
  justify-content: space-between;
  background: white;
  height: 60px;
  width: 92%;
  border-radius: 6px;
  align-items: center;
  margin-top: 10px;
  margin-left: 4%;
}
.audit-left {
  display: flex;
  align-items: center;
  width: 44%;
  justify-content: space-between;
  font-size: 14px;
  margin-left: 10px;
}
.role {
  text-align: center;
  width: 50%;
}
.main ul {
  box-shadow: rgba(3, 3, 3, 0.1) 0 0 10px;
  border-radius: 5px;
  background: #fff;
  margin: 10px;
}

.main ul + ul {
  margin-top: 15px;
}

.main ul li {
  padding: 3px 15px;
  color: #999;
  font-size: 14px;
  text-align: left;
}

.main ul li:nth-child(2) {
  padding-top: 6px;
}

.main ul li:first-child {
  background: #f4f4f4;
  padding: 10px 15px;
  color: rgb(10, 10, 10);
  font-weight: bold;
  border-radius: 5px;
}

.main ul li:first-child span {
  width: auto !important;
}

.main ul li:last-child {
  text-align: right;
}

.main ul li span:first-child {
  width: 4.5rem;
  display: inline-block;
}

.main ul li span:last-child {
  color: #404d5b;
}

.main ul .li_info > span:last-child,
/deep/ .van-dialog__confirm,
.van-dialog__confirm:active {
  color: #1154e7;
}

.main ul .li_info {
  border-bottom: 1px dashed #ececec;
}

.main ul li span.itemright:last-child {
  color: #999;
  float: right;
  font-size: 14px;
  font-weight: 500;
}

.triage {
  font-size: 13px;
  background: #f3f3f3 !important;
  color: #4e4e4e;
  border-radius: 5px;
  margin-right: 10px;
  height: 32px;
  line-height: 32px;
  padding: 0 11px;
  display: inline-block;
  margin-bottom: 5px;
}

.cancel {
  background: #f38383 !important;

  .van-button__text {
    color: #fff !important;
  }
}
.returnVisit {
  background: #1989fa !important;

  .van-button__text {
    color: #fff !important;
  }
}
.signIn {
  background: #1989fa !important;
  border-color: #1989fa;
  color: #fff !important;
}

.van-button__text {
  color: #fff !important;
}
</style>