<template>
  <div>
    <div class="weui-cells weui-cells_form">
      <div class="weui-cell">
        <div class="weui-cell__hd">
          <img :src="photo" alt="" />
        </div>
        <div class="weui-cell__bd">
          <span>{{ nickname }}</span>
        </div>
      </div>
    </div>
    <van-cell center title="消息接收">
      <template #right-icon>
        <van-switch v-model="checked" size="24" @click="control" />
      </template>
    </van-cell>
    <div class="warm-hint">
      <p>温馨提示：</p>
      <div>开启“消息接收”可收到机器异常提醒，关闭将不会收到提醒。</div>
    </div>
    <van-button type="primary" block @click="saveInfo">保存信息</van-button>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      checked: this.$store.state.status == 1 ? true : false,
      photo: localStorage.getItem("weixin_avatar"),
      nickname: localStorage.getItem("nickname"),
      status: this.$store.state.status,
    };
  },
  methods: {
    control() {
      console.log(this.$store.state.status);
      if (this.status == 1) {
        this.status = 0;
      } else {
        this.status = 1;
      }
    },
    saveInfo() {
      var that = this;
      console.log(that.$store.state.roleId);
      that.$http
        .post("/v1/userInfo/setUp", {
          user_info_id: that.$store.state.roleId,
          status: that.status,
        })
        .then((res) => {
          if (res.data.code == 200) {
            that.$store.dispatch("status", this.status);
            Toast("保存成功");
            that.$router.push("/home");
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.weui-cells {
  border-radius: 5px;
  box-shadow: #e0e0e0 0 0 15px;
  margin-top: 15px;
  background: white;
  border-radius: 5px;
  margin: 10px;
}
.weui-cell {
  font-size: 14px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.weui-cell__hd img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 60px;
}
.weui-cell__bd {
  margin-right: 10px;
}
.warm-hint {
  background: #fffbe5;
  margin: 10px 10px;
  padding: 10px 15px 10px 15px;
  box-sizing: border-box;
  font-size: 14px;
  color: #d6951c;
  p {
    margin: 0;
  }
}
.van-cell {
  width: calc(100% - 20px);
  margin: 10px;
  border-radius: 5px;
}
.van-button {
  width: 80%;
  margin: auto 10%;
  position: absolute;
  bottom: 20px;
  height: 36px;
  border-radius: 18px;
  background: linear-gradient(to right, #7670ff, #306ffc);
  border: #306ffc;
}
</style>