<template>
  <div class="home"></div>
</template>

<script>
// import { formatDate } from '../assets/js/utils';
// import { formatDate } from "../../../assets/js/utils";
import httpUrl from "../config/httpUrl"; //引用公共 t/地址

export default {
  created() {
    this.page =
      this.getUrlParam("page") === null
        ? this.getUrlParam("state")
        : this.getUrlParam("page");
    this.getCode();
  },
  data() {
    return {
      //由于是通过微信公众号不同菜单点击进入页面，需要判断
      page: "",
      APP_ID: httpUrl.wx_appid,
      // pass_time: '1245266320',
      // idcard: '372924199503250666',
      httpUrl: httpUrl.vueDomain,
    };
  },
  methods: {
    getCode() {
      // 非静默授权，第一次有弹框  静默授权scope为snsapi_base
      const code = this.getUrlParam("code"); // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      if (code == null || code === "") {
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          this.APP_ID +
          "&redirect_uri="+ this.httpUrl +"/auth&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
      } else {
        this.getOpenId(code); //把code传给后台获取用户信息
      }
    },
    getOpenId(code) {
      var that = this
      // 通过code获取 openId等用户信息，v1/wxCheck 为后台接口
      const params = new URLSearchParams();
      params.append("code", code);
      localStorage.setItem('code',code)
      that.$http
        .post("v1/wxCheck", params)
        .then((res) => {
          if(res.data.code == 200){
            // that.$router.push({ path: "/", query: {} });
            localStorage.setItem('openid',res.data.data.openid)  //openid存入缓存
            localStorage.setItem('token',res.data.data.token)  //token存入缓存
            if(res.data.data.user_info){
              localStorage.setItem('nickname',res.data.data.user_info.nickname)  //昵称存入缓存
              localStorage.setItem('weixin_avatar',res.data.data.user_info.weixin_avatar)  //昵称存入缓存
            }
            if(!localStorage.getItem("now_url") || localStorage.getItem("now_url") == null || localStorage.getItem("now_url") == '/Home'){
              that.$router.push({ path: "/", query: {} });
            }else{
              that.$router.push({ path: localStorage.getItem("now_url"), query: {} });
            }
            localStorage.setItem('msg',res.data.code)
          }else{
            // Toast(res.data.msg)
            localStorage.setItem('msg',res.data.msg)
          }
          
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    getUrlParam(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
  },
};
</script>
