<template>
  <div>
    <van-notice-bar
  wrapable
  :scrollable="false"
  :text="reason"
/>
    <van-form input-align="right">
      <div class="form-info">
        <van-field v-model="hospitalSel" name="医院" label="医院" disabled />
        <van-field
          v-model="username"
          name="姓名"
          label="姓名"
          placeholder="请输入姓名"
        />
        <van-field
          v-model="IDNum"
          type="number"
          name="身份证号"
          label="身份证号"
          placeholder="请输入身份证号"
        />
        <van-field
          v-model="userNum"
          type="number"
          name="操作员号"
          label="操作员号"
          placeholder="请输入操作员号"
        />

        <div>
          <div>
            <van-cell
              class="roleBorder"
              is-link
              title="角色"
              :value="selectRole"
              @click="showRole = true"
            />
            <div class="borderBottom"></div>
          </div>
          <template>
            <van-action-sheet
              v-model="showRole"
              :actions="roleList"
              cancel-text="取消"
              title="角色"
              close-on-click-action
              @select="onRoleType"
            />
          </template>
        </div>
        <van-field
          v-model="phoneNum"
          center
          type="number"
          name="手机号"
          label="手机号"
          placeholder="请输入手机号"
        />
        <van-field
          v-model="sms"
          center
          label="短信验证码"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              style="color: #1989fa; background-color: white; border: none"
              @click="getSms"
              :disabled="disabled"
              >{{ code }}</van-button
            >
          </template>
        </van-field>
      </div>
      <div class="buttonBox">
        <van-button round block type="info" @click="goRegister"
          >重新提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      username: "",
      IDNum: "",
      userNum: "",
      phoneNum: "",
      selectRole: "",
      showRole: false,
      roleList: [],
      hospital: [],
      hospitalSel: "",
      roleId: "",
      show: false,
      note: "",
      second: 61,
      code: "发送验证码",
      sms: "",
      disabled: false,
      userData: {},
      reason: "",
      hos_id:'',
     
      userInfo:{

      }
    };
  },
  created() {
    console.log(this.$route.query.id);
    this.getRole();
  },
  methods: {
    onRoleType(e) {
      this.showRole = false;
      console.log(e.name);
      this.selectRole = e.name;
      this.roleId = e.id;
    },
    getAdminAuditDetail() {
     var that = this;
      console.log(that.$route.query.id);
      that.$http
        .post("v1/userInfo/getMyDetail", {
          user_info_id: that.$route.query.id,
        })
        .then((res) => {
          console.log(res);
          that.userData = res.data.data;
          that.hospitalSel = that.userData.hos_name;
          that.IDNum = that.userData.id_card;
          that.username = that.userData.name;
          that.userNum = that.userData.oper_no;
          that.phoneNum = that.userData.phone;
          that.hos_id = that.userData.hos_id;
          that.roleId = that.userData.role_id
          that.roleList.forEach((el) => {
            if (el.id ==that.userData.role_id) {
              that.selectRole = el.name;
            }
          });
          that.reason = that.userData.note;    
        });
    },
    goRegister() {
       var that = this;
      if (!that.username) {
        Toast("未填写姓名");
        return;
      }
      if (!that.IDNum) {
        Toast("未填写身份证号");
        return;
      }
      if (!that.userNum) {
        Toast("未填写操作员号");
        return;
      }
      if (!that.roleId) {
        Toast("未选择角色");
        return;
      }
      if (!that.phoneNum) {
        Toast("未填写手机号");
        return;
      }
      if (!that.sms) {
        Toast("未填写验证码");
        return;
      }
      that.userInfo = {
        hos_id: that.hos_id,
        role_id: that.roleId,
        name: that.username,
        id_card: that.IDNum,
        oper_no: that.userNum,
        phone: that.phoneNum,
      };
      that.$http
        .post("v1/userInfo/register", {
          hos_id:[that.hos_id],
          role_id: that.roleId,
          name: that.username,
          id_card: that.IDNum,
          oper_no: that.userNum,
          phone: that.phoneNum,
          code: that.sms,
          user_info_id:that.$route.query.id
        })
        .then((res) => {
          if (res.data.code == 200) {
           
            this.$router.push({ path: "/complete", query: {userData:that.userInfo,page:1}});
          } else if (res.data.code == 400) {
            Toast(res.data.msg);
          } else {
            Toast("注册失败");
            console.log(res);
          }
        });
    },
    getRole() {
      var that = this;
      that.$http.post("v1/userInfo/getRole").then((res) => {
        console.log(res);
        that.roleList = res.data.data;
        this.getAdminAuditDetail();
      });
    },
    // getHospital() {
    //   var that = this;
    //   that.$http.post("v1/hospital/getHospital").then((res) => {
    //     console.log(res);
    //     that.hospital = res.data.data;
    //     this.getAdminAuditDetail();
    //   });
    // },
    getSms() {
      var that = this;
         if (!that.username) {
        Toast("未填写姓名");
        return;
      }
      if (!that.IDNum) {
        Toast("未填写身份证号");
        return;
      }
      if (!that.userNum) {
        Toast("未填写操作员号");
        return;
      }
      if (!that.roleId) {
        Toast("未选择角色");
        return;
      }
      if (!that.phoneNum) {
        Toast("未填写手机号");
        return;
      }
      if (that.phoneNum.length == 11 && that.phoneNum.charAt(0) == 1) {
        that.$http
          .post("v1/userInfo/sendSms", {
            phone: that.phoneNum,
          })
          .then((res) => {
            if (res.data.code == 200) {
              console.log(res);
              that.disabled = true;
              var time = setInterval(() => {
                if (that.second) {
                  that.second--;
                  that.code = this.second + "秒后重新发送";
                } else {
                  that.disabled = false;
                  that.second = 61;
                  that.code = "发送验证码";
                  clearInterval(time);
                }
              }, 1000);
            }
          });
      } else {
        Toast("请输入正确的手机号码");
      }
    },
  },
};
</script>
<style  lang="less" scoped>
.form-info {
  width: 90%;
  margin-left: 5%;
  background-color: white;
  border-radius: 15px;
  padding: 10px 0;
  margin-top: 30px;
}
.pick {
  height: 60px;
  width: 92%;

  background: white;
  line-height: 60px;
  margin-left: 4%;
  border-radius: 15px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choice {
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

/deep/.van-field__label {
  color: #323232;
}
.selectiveHospital {
  display: flex;
  flex-wrap: wrap;
}
/* .hospital {
  background-color: white;
  padding: 3px;
  margin: 5px;
  border-radius: 2px;
  padding: 3px 3px;
  font-size: 14px;
  color: #A3A3A3;
} */
.choice-left {
  width: 30%;
  height: 60px;
  padding-left: 20px;
  padding-top: 5px;
}
.van-cell::after,
.borderBottom {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 3px solid #ebedf0;
}
.van-button--block {
  width: 80%;
  font-size: 18px;
}
.van-button--danger {
  color: #ee0a24;
  background-color: white;
}
.buttonBox {
  margin: 32px 0;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
}

/deep/.van-field--disabled .van-field__label {
  color: black;
}
/deep/.van-field__control:disabled,
/deep/.van-field__control[disabled] {
  color: #000;
  opacity: 1;
  -webkit-text-fill-color: #000;
  -webkit-opacity: 1;
}
/deep/.van-cell__value {
  color: #000;
}
.van-cell {
  height: 60px;
  align-items: center;
}
.van-dialog__header {
  padding-top: 12px;

  padding-bottom: 10px;
}
</style>