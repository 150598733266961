<template>
  <div>
    <HeadBar></HeadBar>
    <div class="search">
      <!-- <div>
        <van-dropdown-menu name="total">
          <van-dropdown-item
            v-model="optionValue"
            :options="option"
            title="全部"
            title-class="selected"
          />
        </van-dropdown-menu>
      </div> -->
      <div>
        <van-search
          v-model="value"
          show-action
          placeholder="请输入机器码"
          @search="onSearch"
        >
          <template #action>
            <div @click="onSearch">查询</div>
          </template>
        </van-search>
      </div>
    </div>
    <div class="contain">
      <div class="card" v-for="(item, i) in hardwareList" :key="i">
        <div class="card-head">
          <div class="card-left">{{ item.MachineID }}</div>

          <div class="card-danger" v-if="item.state">
            <p class="circle-danger"></p>
            <p class="status">异常</p>
          </div>
          <div class="card-success" v-else>
            <p class="circle-success"></p>
            <p class="status">正常</p>
          </div>
        </div>
        <div class="card-body card-normal">
          <p :class="item.data[0].Remarks1 == 1 ? 'red' : ''">
            {{ item.data[0].Equipment }}:{{ item.data[0].State }}
          </p>
          <p
            v-if="item.data[1]"
            :class="item.data[1].Remarks1 == 1 ? 'red' : ''"
          >
            {{ item.data[1].Equipment }}:{{ item.data[1].State }}
          </p>
          <p
            v-if="item.data[2]"
            :class="item.data[2].Remarks1 == 1 ? 'red' : ''"
          >
            {{ item.data[2].Equipment }}:{{ item.data[2].State }}
          </p>
          <p
            v-if="item.data[3]"
            :class="item.data[3].Remarks1 == 1 ? 'red' : ''"
          >
            {{ item.data[3].Equipment }}:{{ item.data[3].State }}
          </p>
          <!-- <p>身份证读卡器： 正常</p>
          <p>凭条打印机： 正常</p>
          <p class="card-danger">报告打印机： 连接失败</p> -->
        </div>
        <div class="card-foot">
          <hr />
          {{ item.Time }}
        </div>
      </div>
    </div>
    <LoadingToast v-if="loading"></LoadingToast>
  </div>
</template>



<script>
import { mapState } from "vuex";
import HeadBar from "../components/HeadBar.vue";
import LoadingToast from "../components/loadingToaast.vue"
export default {
  components: {
    HeadBar,
    LoadingToast
  },
  data() {
    return {
      value: "",
      optionValue: 0,
      hardware: [],
      hardwareList: [],
      loading: false,
    };
  },
  created() {
    this.getHardware();
  },
  computed: {
    ...mapState(["roleId"]),
  },
  methods: {
    onSearch() {
      this.getHardware();
    },
    // test(e) {
    //   for (let index = 0; index < e.length; index++) {
    //     const element = e[index];
    //     if (element.State.indexOf("正常" )== -1) {
    //       return false;
    //     }
    //   }
    // },
    getHardware() {
      var that = this;
      that.loading = true;
      that.$http
        .post("v1/zzj", {
          url: "getHardware",
          user_id: that.$store.state.roleId,
          body: {
            MachineID: this.value,
          },
        })
        .then((res) => {
          that.loading = false;
          if (res.status == 200) {
            that.hardware = JSON.parse(res.data).HardwareList;
            var map = {};
            var dest = [];
            for (var i = 0; i < that.hardware.length; i++) {
              var ai = that.hardware[i];
              if (!map[ai.MachineID]) {
                dest.push({
                  MachineID: ai.MachineID,
                  data: [ai],
                });
                map[ai.MachineID] = ai;
              } else {
                for (var j = 0; j < dest.length; j++) {
                  var dj = dest[j];
                  if (dj.MachineID == ai.MachineID) {
                    dj.data.push(ai);
                    break;
                  }
                }
              }
            }
            that.hardwareList = dest;
            console.log(that.hardwareList);
            that.hardwareList.forEach((el) => {
              for (let index = 0; index < el.data.length; index++) {
                const element = el.data[index];
                if (element.Remarks1 == 1) {
                  that.$set(el, "state",  true);
                  return;
                } else {
                  that.$set(el, "state", false);
                }
              }
            });
          } else {
            console.log(res);
          }
        });
    },
  },
  watch: {
    roleId() {
      this.getHardware();
    },
  },
};
</script>
<style  lang="less" scoped>
.search {
  height: 44px;
  width: 100%;
margin-top: 50px;
  color: white;
}
.contain {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
}
.card {
  width: 40%;
  background: #fff;
  height: 134px;
  border-radius: 6px;
  margin-top: 15px;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 3%;
}
.card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}
.card-left {
  font-weight: bold;
  font-size: 18px;
}
.card-success {
  color: #61d6a6;
  font-size: 12px;
  display: flex;
}
.card-danger {
  color: #e54f4f;
  font-size: 12px;
  display: flex;
}
.card-normal {
  color: #666666;
  font-size: 12px;
}
.card-body {
  font-size: 12px;
}
.card-body > p {
  margin: 0;
  font-size: 10px;
}
.card-foot {
  position: absolute;
  bottom: 6px;
  font-size: 12px;
  color: #666666;
  width: calc(100% - 20px);
}
.van-search {
  padding: 5px 12px;
}
/deep/.van-dropdown-menu[name="total"] .van-dropdown-menu__bar {
  height: 44px;
  box-shadow: none;
}
/deep/.van-dropdown-menu[name="total"]
  .van-dropdown-menu__bar
  .van-dropdown-menu__item {
  width: 100px;
}

/deep/.van-search .van-cell {
  padding: 3px 8px 3px 0;
  background-color: #f3f3f3;
}
.circle-danger {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #e54f4f;
}
.circle-success {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #61d6a6;
}
.status {
  margin: 8px;
}
/deep/.van-dropdown-menu[name="total"] .van-dropdown-menu__bar {
  height: 44px;
  box-shadow: none;
}
/deep/.van-dropdown-menu[name="total"]
  .van-dropdown-menu__bar
  .van-dropdown-menu__item {
  width: 120px;
}
/deep/.van-dropdown-menu[name="total"]
  .van-dropdown-menu__bar
  .van-dropdown-menu__item
  .van-dropdown-menu__title {
  background: #f3f3f3;
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
/deep/.van-dropdown-menu[name="total"]
  .van-dropdown-menu__bar
  .van-dropdown-menu__item
  .van-dropdown-menu__title::after {
  position: absolute;
  top: 50%;
  right: 12px;
  border-color: transparent transparent #666666 #666666;
}
.red {
  color: #e54f4f;
}

.loading {
  position: fixed;
  z-index: 5000;
  font-size: 10px;
  width: 130px;
  height: 130px;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: #4c4c4c;
  box-sizing: border-box;
  line-height: 1.4;
  font-size: 17px;
  padding-top: 20px;
}
.loading_content {
  color: white;
}
</style>
