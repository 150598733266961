<template>
  <div>
    <van-form input-align="right">
      <div class="form-info">
        <van-field v-model="hospitalSel" name="医院" label="医院" disabled />
        <van-field v-model="username" name="姓名" label="姓名" disabled />
        <van-field
          v-model="IDNum"
          type="number"
          name="身份证号"
          label="身份证号"
          disabled
        />
        <van-field
          v-model="userNum"
          type="number"
          name="操作员号"
          label="操作员号"
          disabled
        />

        <div>
          <div>
            <van-cell
              class="roleBorder"
              is-link
              title="角色"
              :value="selectRole"
              @click="showRole = true"
            />
            <div class="borderBottom"></div>
          </div>
          <template>
            <van-action-sheet
              v-model="showRole"
              :actions="roleList"
              cancel-text="取消"
              title="角色"
              close-on-click-action
              @select="onRoleType"
            />
          </template>
        </div>
        <van-field
          v-model="phoneNum"
          type="number"
          name="手机号"
          label="手机号"
          disabled
        />
      </div>
      <div class="buttonBox">
        <van-button round block type="danger" @click="cancel">拒绝</van-button>
        <van-button round block type="info" @click="onSubmit">提交</van-button>
      </div>
    </van-form>
    <van-dialog
      v-model="show"
      title="审核原因"
      show-cancel-button
      @confirm="reject"
    >
      <van-cell-group>
        <van-field
          v-model="note"
          placeholder="请输入审核不通过原因"
          type="textarea"
        />
      </van-cell-group>
    </van-dialog>
  </div>
</template>
<script>
// import { Dialog } from "vant";
export default {
  data() {
    return {
      username: "",
      IDNum: "",
      userNum: "",
      phoneNum: "",
      selectRole: "",
      showRole: false,
      roleList: [],
      hospital: [],
      hospitalSel: "",
      roleId: "",
      show: false,
      note: "",
    };
  },
  created() {
    this.getRole();
  },
  methods: {
    onRoleType(e) {
      this.showRole = false;
      console.log(e.name);
      this.selectRole = e.name;
      this.roleId = e.id;
    },
    getAdminAuditDetail() {
      var that = this;
      that.$http
        .post("v1/userInfo/getAdminAuditDetail", {
          user_info_id: this.$route.query.userid,
          admin_id:this.$store.state.roleId,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            var data = res.data.data;
            that.username = data.name;
            that.IDNum = data.id_card;
            that.userNum = data.oper_no;
            that.roleId = data.role_id;
            that.roleList.forEach((el) => {
              if (el.id == data.role_id) {
                that.selectRole = el.name;
              }
            });
             that.hospitalSel = data.hos_name
         that.roleId = data.role_id;
            that.phoneNum = data.phone;
          }
        });
    },
    onSubmit() {
      var that = this;
      that.$http
        .post("v1/userInfo/adminAudit", {
          user_info_id: that.$route.query.userid,
          admin_id: this.$store.state.roleId,
          is_audit: 1,
          role_id: that.roleId,
          note: "",
        })
        .then((res) => {
          console.log(res);
          that.$router.push("/auditList");
        });
    },
    getRole() {
      var that = this;
      that.$http
        .post("v1/userInfo/getRole", {
          audit: 1,
        })
        .then((res) => {
          console.log(res);
          that.roleList = res.data.data;
          that.getAdminAuditDetail();
        });
    },
    // getHospital() {
    //   var that = this;
    //   that.$http.post("v1/hospital/getHospital").then((res) => {
    //     console.log(res);
    //     that.hospital = res.data.data;
    //     this.getAdminAuditDetail();
    //   });
    // },
    cancel() {
      this.show = true;
    },
    chooseHospital() {
      this.$router.push("/chooseHospital");
    },
    reject() {
      var that = this;
      that.$http
        .post("v1/userInfo/adminAudit", {
          user_info_id: that.$route.query.userid,
          admin_id: this.$store.state.roleId,
          is_audit: 2,
          note: that.note,
          role_id: that.roleId,
        })
        .then((res) => {
          console.log(res);
          that.$router.push("/auditList");
        });
    },
  },
};
</script>
<style  lang="less" scoped>
.form-info {
  width: 90%;
  margin-left: 5%;
  background-color: white;
  border-radius: 15px;
  padding: 10px 0;
  margin-top: 30px;
}
.pick {
  height: 60px;
  width: 92%;

  background: white;
  line-height: 60px;
  margin-left: 4%;
  border-radius: 15px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choice {
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

/deep/.van-field__label {
  color: #323232;
}
.selectiveHospital {
  display: flex;
  flex-wrap: wrap;
}
/* .hospital {
  background-color: white;
  padding: 3px;
  margin: 5px;
  border-radius: 2px;
  padding: 3px 3px;
  font-size: 14px;
  color: #A3A3A3;
} */
.choice-left {
  width: 30%;
  height: 60px;
  padding-left: 20px;
  padding-top: 5px;
}
.van-cell::after,
.borderBottom {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 3px solid #ebedf0;
}
.van-button--block {
  width: 40%;
  font-size: 18px;
}
.van-button--danger {
  color: #ee0a24;
  background-color: white;
}
.buttonBox {
  margin: 32px 0;
  background-color: #f3f3f3;
  display: flex;
  justify-content: space-evenly;
}

/deep/.van-field--disabled .van-field__label {
  color: black;
}
/deep/.van-field__control:disabled,
/deep/.van-field__control[disabled] {
  color: #000;
  opacity: 1;
  -webkit-text-fill-color: #000;
  -webkit-opacity: 1;
}
/deep/.van-cell__value {
  color: #000;
}
.van-cell {
  height: 60px;
  align-items: center;
}
.van-dialog__header {
  padding-top: 12px;

  padding-bottom: 10px;
}
</style>