<template>
  <div class="fancy">
    <div class="home-head">
      <div class="head-img">
        <van-image
          width="81.5px"
          height="16px"
          lazy-load
          :src="require('../assets/images/logo.png')"
        />
      </div>
      <div>
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="value"
            :options="option"
            @change="change(value)"
          />
        </van-dropdown-menu>
      </div>
    </div>
  </div>
</template>

<script>
// import { Toast } from "vant";
export default {
  data() {
    return {
      value: "",
      option: [],
    };
  },
  created() {
    this.getAuthUser();
  },
  methods: {
    getAuthUser() {
      var that = this;
      that.$http.post("v1/userInfo/getAuthUser").then((res) => {
        if (res.data.code == 200) {
          that.option = res.data.data;
          console.log(that.option, 123);
          that.option.forEach((element) => {
            that.$set(
              element,
              "text",
              element.hos_name + " [" + element.role + "]"
            );
            that.$set(element, "value", element.id);
          });

          if (that.$store.state.roleId == 0) {
            that.$store.dispatch("change", res.data.data[0].id);
            // that.$store.dispatch('status', res.data.data[0].is_receive)
            that.$store.dispatch("status", res.data.data[0].is_receive);
            that.$store.dispatch("role", res.data.data[0].role_id);
            that.$emit("childFn", res.data.data[0]);
          } else {
            res.data.data.forEach((element) => {
              if (element.id == that.$store.state.roleId) {
                that.$store.dispatch("status", element.is_receive);
                that.$store.dispatch("role", element.role_id);
                that.$emit("childFn", element);
              }
            });
          }
          that.value = that.$store.state.roleId;
        } else {
          that.$store.dispatch("change", 0);
        }
        // else if (res.data.code == 200) {
        //   that.$store.dispatch("change", 0);
        // }
      });
    },
    change(e) {
      var that = this;
      that.$store.dispatch("change", e);
      localStorage.setItem("roleId", e);
      that.option.forEach((element) => {
        if (element.value == e) {
          that.$store.dispatch("status", element.is_receive);
          that.$store.dispatch("role", element.role_id);
          that.$emit("childFn", element);
        }
      });
    },
  },
};
</script>
<style  lang="less" scoped>
.fancy{

.home-head {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background: #fff;
    position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
/deep/.van-dropdown-menu__bar {
  box-shadow: none;
  height: 44px;
  margin-right: 20px;
}
.head-img {
  margin-left: 15px;
}
/deep/.van-dropdown-menu__title::after {
  border-color: transparent transparent #666666 #666666;
}
/deep/.van-cell {
  display: flex;

  justify-content: space-between;
}
/deep/.van-cell__title,
.van-cell__value {
  flex: none;
}
}
</style>
