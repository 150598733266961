<template>
  <div>
    <HeadBar></HeadBar>
    <!-- <div class="search">
      <div>
        <van-search
          v-model="value"
          show-action
          placeholder="请输入机器编号"
          @search="onSearch"
        >
          <template #action>
            <div @click="onSearch">查询</div>
          </template>
        </van-search>
      </div>
    </div> -->
    <div class="content">
      <div class="individual" v-for="(item, index) in machineList" :key="index">
        <div class="individual_top">
          <div style="font-weight: bold" class="individual_left">
            {{ item.ID }}
          </div>
          <div class="individual_right">
            <div class="machine">
              <div style="font-weight: bold">{{ item.MachineID }}</div>
            </div>
            <div class="details">
              <div class="message">
                <div>配置名称</div>
                <div>{{ item.ConfigExplain }}</div>
              </div>
              <div class="message">
                <div>字段名称</div>
                <div>{{ item.ConfigName }}</div>
              </div>
              <div class="message">
                <div>参数</div>
                <div>{{ item.ConfigValue }}</div>
              </div>
              <div class="message">
                <div>备注</div>
                <div>{{ item.Remark ? item.Remark : "无" }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="individual_foot">
          <div  @click="goEdit(item)"><van-icon name="edit" />编辑</div>
          <div  @click="deleteModule(item)"><van-icon name="delete-o" />删除</div>
        </div>
      </div>
    </div>
    <div class="addconfig">
      <van-button plain type="info" @click="addConfig">+新增配置</van-button>
    </div>
    <Nodata v-if="noData"></Nodata>
    <LoadingToast v-if="loading"></LoadingToast>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Dialog, Toast } from "vant";
import LoadingToast from "../components/loadingToaast.vue";
import HeadBar from "../components/HeadBar.vue";
import Nodata from "../components/noData.vue";
export default {
  components: {
    HeadBar,
    Nodata,
    LoadingToast,
  },
  data() {
    return {
      value: "",
      machineId: this.$route.query.machineId,
      machineList: [],
      noData: true,
      loading: true,
    };
  },
  computed: {
    ...mapState(["roleId"]),
  },
  methods: {
    onSearch() {
      //   console.log(this.value);
      this.machineId = this.value;
      this.getList();
    },
    getList() {
      var that = this;
      that.noData = true;
      that.loading = true;
      that.machineList = [];
      that.$http
        .post("v1/zzj", {
          url: "MachineConfig",
          user_id: that.$store.state.roleId,
          body: {
            // MachineID: "9999",
            MachineID: that.machineId,
          },
        })
        .then((res) => {
          // console.log(res);
          
          that.loading = false;
          console.log(JSON.parse(res.data));
          that.machineList = JSON.parse(res.data).MachineConfigList;
          if (that.machineList.length) {
            that.noData = false;
          }
        });
    },
    //去编辑
    goEdit(e) {
      this.$router.push({ path: "/machineEdit", query: { module: JSON.stringify(e) } });
    },
      //DeleteFunction删除
    deleteModule(e) {
      var that = this;
      Dialog.confirm({
        title: "删除",
        message: "您确定要删除该机器配置吗？",
      })
        .then(() => {
          that.$http
            .post("v1/zzj", {
              url: "DeleteConfig",
              user_id: that.$store.state.roleId,
              body: {
                // MachineID: "9999",
                ID: e.ID,
              },
            })
            .then((res) => {
              if (JSON.parse(res.data).Code == "0") {
                Toast("删除成功");
                that.getList()
              } else {
                Toast(JSON.parse(res.data).Message);
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
     //增加配置
    addConfig() {
      this.$router.push({path:"/addMachine",query:{
      machineId: this.$route.query.machineId,

      }});
    },
  },
  watch: {
    roleId() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style  lang="less" scoped>
.search {
  height: 44px;
  width: 100%;
  color: white;
}
.van-search {
  padding: 5px 12px;
}
/deep/.van-search .van-cell {
  padding: 3px 8px 3px 0;
  background-color: #f3f3f3;
}

.content {
  width: 100%;
      padding-top: 44px;
   padding-bottom: 60px;
  .individual {
    box-sizing: border-box;
    width: 94%;
    margin: 20px 3% 0 3%;
    background: white;
    border-radius: 6px;
    padding: 10px 12px 10px 12px;
    .line {
      margin: 5px auto;
      height: 1px;
      //   background: #c3c1c1;
      border-bottom: #c3c1c1 1px solid;
    }
    .individual_top {
      display: flex;
      justify-content: space-between;
      .individual_left {
        width: 12%;
        padding-top: 4px;
        font-size: bold;
      }
      .individual_right {
        width: 88%;
        .machine {
          margin-bottom: 5px;
          line-height: 26px;
          font-size: bold;
        }
        .details {
          .message {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #676565;
            margin-bottom: 5px;

            div:nth-child(1) {
              text-align: right;
              width: 20%;
              margin-right: 10%;
              height: 25px;
              line-height: 25px;
            }
            div:nth-child(2) {
              width: 70%;
              vertical-align: bottom;
              min-height: 25px;
              line-height: 25px;
            }
          }
        }
      }
    }
    .individual_foot {
      color: gray;
      text-align: center;
      font-size: 14px;
      margin-top: 5px;
      display: flex;
      justify-content: space-evenly;
      div:nth-child(1){
        width: 50%;
      }
      div:nth-child(2) {
        color: red;
        width: 50%;
      }
    }
  }
}
.addconfig {
  position: fixed;
  bottom: 0;
  background: #eff2f8;
  width: 100%;
  left: 0;
  height: 50px;
  padding-top: 5px;
  z-index: 20;
  text-align: center;
}
.van-button--plain {
  height: 40px;
  width: 90%;
  border-radius: 10px;
}
</style>