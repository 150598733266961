<template>
  <div class="home">
    <HeadBar></HeadBar>
    <div class="home-body">
      <div
        class="body-management"
        v-for="(hositem, a) in hospitalMenu"
        :key="a"
      >
        <div class="top">
          <van-image
            width="17px"
            height="17px"
            lazy-load
            :src="hositem.icon"
            style="margin-right: 7px"
          />{{ hositem.title }}
        </div>
        <div class="management-list">
          <div
            class="management"
            v-for="(item, i) in hositem.son"
            :key="i"
            @click="goNext(item.url)"
          >
            <van-image width="44px" height="44px" lazy-load :src="item.icon" />
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
<van-button color="#eff2f8" style="color:#eff2f8;width:100%;position:absolute;bottom:0" @click = "clear">清空缓存</van-button>
      <!-- <div class="body-management">
          <van-image
            width="17px"
            height="17px"
            lazy-load
            :src="hospitalMenu[1].icon"
            style="margin-right: 7px"
          />{{ hospitalMenu[1].title }}
        </div>
        <div class="management-list">
          <div
            class="management"
            v-for="(item, i) in hospitalMenu[1].son"
            :key="i"
            @click="goNext(item.url)"
          >
            <van-image width="44px" height="44px" lazy-load :src="item.icon" />
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div> -->
      <!-- <div class="body-management">
        <div class="top">
          <van-image
            width="17px"
            height="17px"
            lazy-load
            :src="hospitalMenu[2].icon"
            style="margin-right: 7px"
          />{{ hospitalMenu[2].title }}
        </div>
        <div class="management-list">
          <div
            class="management"
            v-for="(item, i) in hospitalMenu[2].son"
            :key="i"
            @click="goNext(item.url)"
          >
            <van-image width="44px" height="44px" lazy-load :src="item.icon" />
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import HeadBar from "../components/HeadBar.vue";
import { mapState } from "vuex";
export default {
  components: {
    HeadBar,
  },
  data() {
    return {
      hospitalMenu: {},
    };
  },
  computed: {
    ...mapState(["roleId"]),
  },
  watch: {
    roleId() {
      this.getHospitalMenu();
    },
  },
  mounted() {
    this.getHospitalMenu();
  },
  methods: {
    goNext(e) {
      this.$router.push(e);
    },
    getHospitalMenu() {
      var that = this;
      console.log(that.$store.state.roleId);
      that.$http
        .post("/v1/userInfo/getHospitalMenu", {
          user_info_id:that.$store.state.roleId
        })
        .then((res) => {
          if (res.data.code == 200) {
            console.log(111,res);
            that.hospitalMenu = res.data.data;
          }
        });
    },
    clear(){
      localStorage.clear()
    }
  },
};
</script>
<style scoped>
#app {
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
.home {
  background-color: #eff2f8;
  height: 100%;
      padding-top: 44px;
}
/* .home-head {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
  background: #fff;
}
/deep/.van-dropdown-menu__bar {
  box-shadow: none;
  height: 44px;
} */
.home-body {
  width: 100%;
}
.body-management {
  width: 100%;
}
.top {
  padding-left: 20px;
  margin: 15px 0;
  display: flex;
  align-items: center;
}
.management-list {
  display: flex;
  height: 90px;
  width: 92%;
  margin-left: 4%;
  margin-right: 4%;
  background-color: #fff;
  border-radius: 6px;
}
.management {
  text-align: center;
  width: 25%;
  padding-top: 13px;
}
.config {
  padding-left: 20px;
  color: #1c384d;
}
.configuration {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.van-button--default{
  color: #eff2f8;
}
</style>