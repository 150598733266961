<template>
  <div>
    <van-form @submit="onSubmit">
      <van-field v-model="id" name="ID" label="ID" disabled />
      <van-field
        v-model="machineId"
        name="机器编号"
        label="机器编号"
        disabled
      />
      <van-field
        v-model="functionName"
        name="功能名称"
        label="功能名称"
        @input="showList"
        @click-input="firstClick"
        @blur="leaveField"
      />
      <div class="search-content" ref="search" v-if="functionNameList.length">
        <ul>
          <li
            class="search-item border-bottom"
            v-for="(item, index) of functionNameList"
            :key="index"
            @click="changeExplain(item, index)"
          >
            {{ item }}
          </li>
          <!-- <li class="search-item border-bottom" >没有找到匹配数据</li> -->
        </ul>
      </div>
      <van-field v-model="buttonName" name="按钮名称" label="按钮名称" />

      <van-field
        v-model="use"
        name="是否启用"
        label="是否启用"
        disabled
        is-link
        @click="show = true"
      />
      <van-field
        v-model="sortOrder"
        type="number"
        name="排列顺序"
        label="排列顺序"
      />
      <van-field v-model="remark" name="备注" label="备注" type="textarea" />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
    <div>
      <van-overlay :show="show" @click="show = false" />
      <van-picker
        title="是否启用"
        show-toolbar
        :default-index="using"
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        v-if="show"
      />
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      id: JSON.parse(this.$route.query.module).ID,
      machineId: JSON.parse(this.$route.query.module).MachineID,
      buttonName: JSON.parse(this.$route.query.module).MKM,
      functionName: JSON.parse(this.$route.query.module).MKMS,
      using: JSON.parse(this.$route.query.module).SFQY,
      use: JSON.parse(this.$route.query.module).SFQY == "0" ? "不启用" : "启用",
      sortOrder: JSON.parse(this.$route.query.module).MKXH,
      remark: JSON.parse(this.$route.query.module).BZ,
      columns: ["不启用", "启用"],
      show: false,
      allLists: [],
      configList: [], //搜索得到的配置名称列表
      configLists: [], //全部配置名称（不变）
      timer: "",
      searchStop: false,
      chooce: false,
      first: true,
      functionNameLists: [],
      functionNameList: [],
    };
  },
  methods: {
    onSubmit() {
      var that = this;
      if (!that.buttonName) {
        Toast("请填写按钮名称");
        return false;
      }
      that.functionNameLists.forEach((e) => {
        if (e == that.functionName) {
          that.chooce = true;
        }
      });
      if (!that.chooce) {
        Toast("请选择正确的功能名称");
        return;
      }
      if (!that.functionName) {
        Toast("请填写功能名称");
        return false;
      }
      // if (!that.using) {
      //   Toast("请选择是否启用");
      //   return false;
      // }
      if (!that.sortOrder) {
        Toast("请填写顺序");
        return false;
      }

      var obj = {
        ID: that.id,
        MachineID: that.machineId,
        MKM: that.buttonName,
        MKMS: that.functionName,
        SFQY: that.using,
        MKXH: that.sortOrder,
        BZ: that.remark,
      };
      console.log(JSON.stringify(obj));
      that.$http
        .post("v1/zzj", {
          url: "updateFunction",
          user_id: that.$store.state.roleId,
          body: {
            jsonStr: JSON.stringify(obj),
            // MachineID:that.machineId
          },
        })
        .then((res) => {
          console.log(res);
          if (JSON.parse(res.data).Code == "0") {
            Toast.success("修改成功");
            that.$router.go(-1);
          } else {
            Toast.fail(JSON.parse(res.data).Message);
          }
        });
    },
    onConfirm(value, index) {
      this.show = false;
      if (value == "启用") {
        this.use = "启用";
        this.using = 1;
      } else if (value == "不启用") {
        this.use = "不启用";
        this.using = 0;
      }
    },
    onCancel() {
      this.show = false;
    },
    getConfigExplain() {
      var that = this;
      that.$http
        .post("v1/zzj", {
          url: "getAllFunction",
          user_id: that.$store.state.roleId,
        })
        .then((res) => {
          if (JSON.parse(res.data).Code == 0) {
            var list = JSON.parse(res.data).FunctionList;
            that.allLists = list;
            //  console.log(list);
            // list.forEach((element) => {
            //   that.configLists.push(element.MKM);
            // });
            //functionNameList
            list.forEach((element) => {
              that.functionNameLists.push(element.MKMS);
            });
            console.log(that.functionNameLists);
          }
        });
    },
    changeExplain(e, i) {
      this.buttonName = this.allLists[i].MKM;
      this.remark = this.allLists[i].BZ;
      this.functionName = e;
      this.sortOrder = this.allLists[i].MKXH;
      this.using = this.allLists[i].SFQY;
      this.use = this.using == 1 ? "启用" : "不启用";
      this.functionNameList = [];
    },
    //输入框变化显示
    showList(n) {
      this.searchStop = false;
      this.functionNameLists.forEach((e) => {
        if (e == n) {
          this.functionNameList = [];
          this.searchStop = true;
          return;
        }
      });
      if (this.searchStop) {
        return false;
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (!this.functionName) {
        this.functionNameList = this.functionNameLists;
        return;
      }

      this.timer = setTimeout(() => {
        var result = [];
        this.functionNameLists.forEach((element) => {
          if (element.indexOf(this.functionName) > -1) {
            result.push(element);
          }
        });
        console.log(result);
        this.functionNameList = result;
      }, 100);
    },
    //
    firstClick() {
      if (this.first) {
        this.first = false;
        this.functionNameList = this.functionNameLists;
      }
    },
    leaveField() {
      this.first = true;
      setTimeout(() => {
        this.functionNameList = [];
      }, 100);
      // this.configList = [];
    },
  },
  created() {
    this.getConfigExplain();
  },
};
</script>

<style lang="less" scoped>
/deep/.van-field__control:disabled {
  color: black;
  -webkit-text-fill-color: black;
}
/deep/ .van-field__label {
  color: black;
}
.search-content {
  width: 62%;
  /* padding-left: 7.575rem; */
  color: #646566;
  background: white;
  position: fixed;
  top: 132px;
  left: 7rem;
  z-index: 100;
  padding-left: 0.75rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px 1px rgba(0, 0, 0, 0.18);
  // border: gray solid 1px;
  border-radius: 0 0 3px 3px;
  max-height: 60vh;
  overflow-y: scroll;
  border-top: unset;
  border-left: unset;
  .search-item {
    // width: calc(100% - 7.575rem);
    height: 30px;
    border-bottom: #f8f8f8 1px solid;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.submit {
  margin: 5%;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 90%;
}
/deep/.van-picker {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 20;
}
</style>