<template>
  <div style="width: 100%; height: 100%">
    <HeadBar v-if="!isColumnar"></HeadBar>
    <!-- <div class="machine-title">
      <div class="machine-left">机器编号</div>

      <van-dropdown-menu name="total">
        <van-dropdown-item
          v-model="optionValue"
          :options="option"
          title="全部"
          title-class="selected"
        />
      </van-dropdown-menu>
      <div class="machine-right">
        <van-icon
          name="https://b.yzcdn.cn/vant/icon-demo-1126.png"
          @click="columnar()"
        />
      </div>
    </div> -->
    <div class="search" v-if="!isColumnar">
      <!-- <div>
        <van-dropdown-menu name="total">
          <van-dropdown-item
            v-model="optionValue"
            :options="option"
            title="全部"
            title-class="selected"
          />
        </van-dropdown-menu>
      </div> -->
      <div>
        <van-search v-model="value" placeholder="请输入机器码" />
      </div>
    </div>
    <div class="time-picker" v-if="!isColumnar">
      <div class="time">
        <div @click="showTime1 = true" style="position: relative">
          {{ date1 }}
          <p class="arrow"></p>
        </div>
        <div class="_pop" v-show="showTime1">
          <van-overlay :show="true">
            <van-datetime-picker
              v-model="currentDate1"
              type="date"
              title="选择年月日"
              :min-date="minDate1"
              :max-date="maxDate1"
              @confirm="onConfirm1"
              @cancel="cancelOne"
          /></van-overlay>
        </div>
      </div>
      <div class="time">
        <div @click="showTime2 = true" style="position: relative">
          {{ date2 }}
          <p class="arrow"></p>
        </div>
        <div class="_pop" v-show="showTime2">
          <van-overlay :show="true">
            <van-datetime-picker
              v-model="currentDate2"
              type="date"
              title="选择年月日"
              :min-date="minDate2"
              :max-date="maxDate2"
              @confirm="onConfirm2"
              @cancel="cancelTwo"
          /></van-overlay>
        </div>
      </div>
      <div @click="inquire">查询</div>
    </div>

    <div :class="!isFull ? 'content' : 'contents'">
      <div
        :class="[
          'echarts',
          !isColumnar ? 'vertical_echarts' : 'landscape_echarts',
        ]"
      >
        <div
          :class="['title', !isColumnar ? 'vertical_title' : 'landscape_title']"
        >
          <p style="font-weight: 500; fontsize: 14px; margin: 15px 0 0 20px">
            功能使用
          </p>
          <van-image
            width="16"
            height="16"
            style="margin: 20px 10px 0 0"
            @click="columnar()"
            :src="require('../assets/images/vertical.png')"
          />
        </div>
        <div id="main" :class="!isColumnar ? 'vertical' : 'landscape'"></div>
      </div>
    </div>
    <LoadingToast v-if="loading"></LoadingToast>
  </div>
</template>
<script>
import { mapState } from "vuex";
import HeadBar from "../components/HeadBar.vue";
import { formatDate } from "../assets/js/utils";
import * as echarts from "echarts";
import LoadingToast from "../components/loadingToaast.vue"
export default {
  components: {
    HeadBar,
    LoadingToast
  },
  data() {
    return {
      value: "",
      optionValue: 0,
      option: [
        { text: "全部", value: 0 },
        { text: "全部1", value: 1 },
        { text: "全部2", value: 2 },
      ],
      minDate1: new Date(2020, 0, 1),
      maxDate1: new Date(),
      currentDate1: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      minDate2: new Date(2020, 0, 1),
      maxDate2: new Date(),
      currentDate2: new Date(),
      showTime1: false,
      showTime2: false,
      date1: "2022-03-18",
      date2: "",
      myChart: null, // 图表需要字段
      isFull: false,
      isColumnar: false,
      chartOption: null,
      funcitonList: [],
      functionCount: [],
      functionName: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(["roleId"]),
  },
  created() {
    this.date1 = formatDate(
      new Date(new Date().setMonth(new Date().getMonth() - 1)),
      "yyyy-MM-dd"
    );
    this.date2 = formatDate(this.currentDate2, "yyyy-MM-dd");
    this.inquire();
  },
  methods: {
    cancelOne() {
      this.showTime1 = false;
    },
    cancelTwo() {
      this.showTime2 = false;
    },
    echarts() {
      this.myChart = echarts.init(document.getElementById("main"));
      // 绘制图表
      this.chartOption = {
        title: {
          left: 10,
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          bottom: 90,
        },
        dataZoom: [
          {
            type: "inside",
          },
          {
            type: "slider",
          },
        ],
        xAxis: {
          data: this.functionName,
          silent: false,
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            rotate: 45, //倾斜度 -90 至 90 默认为0
            margin: 2,
          },
        },
        yAxis: {
          splitArea: {
            show: false,
          },
          axisLabel: {
            show: true,
            inside: false,
            rotate: 45,
            margin: 0,
          },
        },
        series: [
          {
            type: "bar",
            data: this.functionCount,
            // Set `large` for large data amount
            large: true,
          },
        ],
      };

      this.myChart.setOption(this.chartOption);
    },
    resize() {
      this.myChart.resize(); // 窗口大小发生变化的时候重置
    },
    columnar() {
      this.isColumnar = !this.isColumnar;
      this.isFull = !this.isFull;
    },
    //第一个时间选择
    onConfirm1(date) {
      this.showTime1 = false;
      var strMonth = formatDate(date, "yyyy-MM-dd");
      this.currentDate1 = strMonth;
      console.log(this.currentDate1);
      this.date1 = strMonth;
      console.log(typeof this.date1);
    },
    //第二个时间选择
    onConfirm2(date) {
      this.showTime2 = false;
      var strMonth = formatDate(date, "yyyy-MM-dd");
      this.currentDate2 = strMonth;
      this.date2 = strMonth;
      console.log(this.currentDate2);
    },
    inquire() {
      var that = this;
      that.functionList = [];
      (that.functionCount = []),
        (that.functionName = []),
        (that.loading = true);
      if (this.value == "") {
        var obj = {
          url: "getFunctionStatistics",
          user_id: that.$store.state.roleId,
          body: {
            KSRQ: that.date1,
            JSRQ: that.date2,
          },
        };
      } else {
        var obj = {
          url: "getFunctionStatistics",
          user_id: that.$store.state.roleId,
          body: {
            MachineID: that.value,
            KSRQ: that.date1,
            JSRQ: that.date2,
          },
        };
      }
      that.$http
        .post("v1/zzj", obj)
        .then((res) => {
          var data = JSON.parse(res.data);
          that.loading = false;
          if (data.Code == 0) {
            that.functionList = data.FunctionList;
            console.log(111, that.functionList);
            if (that.functionList.length > 0) {
              that.functionList.forEach((element) => {
                that.functionCount.push(element.Count);
                that.functionName.push(element.Name);
              });
              that.echarts();
            }
          }
          console.log(data);
        });
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  mounted() {
    window.addEventListener("resize", this.resize);
  },

  watch: {
    isColumnar: {
      handler() {
        if (this.myChart) {
          this.$nextTick(() => this.resize());
          this.myChart.setOption(this.chartOption, true);
        }
      },
    },
    roleId() {
      this.inquire();
    },
  },
};
</script>
<style  lang="less" scoped>
p {
  margin: 0;
  padding: 0;
}
.machine-title {
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  background: white;
}
.machine-left {
  margin: 0 10px 0 20px;
}
.machine-right {
  background: #f3f3f3;
  padding: 4px;
  border-radius: 3px;
  margin-left: 30px;
}
._pop .van-picker {
  position: fixed !important;
  z-index: 2;
  width: 100%;
  bottom: 0;
  left: 0;
}
.time-picker {
  display: flex;
  background: white;
  height: 44px;
  align-items: center;
}
.time {
  background: #f3f3f3;
  margin: 0px 10px;
  width: 39%;
  text-align: center;
  padding: 5px 0;
}

/deep/.van-dropdown-menu[name="total"] .van-dropdown-menu__bar {
  height: 44px;
  box-shadow: none;
}
/deep/.van-dropdown-menu[name="total"]
  .van-dropdown-menu__bar
  .van-dropdown-menu__item {
  width: 200px;
}
/deep/.van-dropdown-menu[name="total"]
  .van-dropdown-menu__bar
  .van-dropdown-menu__item
  .van-dropdown-menu__title {
  background: #f3f3f3;
  width: 180px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}

/deep/.van-dropdown-menu[name="total"]
  .van-dropdown-menu__bar
  .van-dropdown-menu__item
  .van-dropdown-menu__title::after {
  position: absolute;
  top: 50%;
  right: 20px;
  border-color: transparent transparent #666666 #666666;
}
.arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -5px;
  border: 3px solid;
  border-color: transparent transparent #666666 #666666;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  opacity: 0.8;
  content: "";
}
* {
  margin: 0;
  padding: 0;
}

.contents {
  width: 100%;
  height: 100vh;
  background: #fff;
}

.content {
  width: 100%;
  height: 60vh;
  background: #fff;
}

.echarts {
  position: relative;
}

.landscape_echarts {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.vertical_echarts {
  width: 100%;
  background-color: #fff;
}

.landscape {
  width: 100vh;
  height: 100vw;
}

.vertical {
  width: 100%;
  height: 32rem;
}

.title {
  display: flex;
  align-items: center;
  /* transform: translateY(.5rem); */
  position: absolute;
  top: 0;
  justify-content: space-between;
  z-index: 9;
}

.landscape_title {
  width: 100vh;
  height: 1rem;
}

.vertical_title {
  width: 100%;
  height: 1rem;
}

.title h5 {
  text-indent: 0.3rem;
  font-size: 0.4rem;
}

.title span {
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  font-size: 0.4rem;
  margin-right: 0.4rem;
}
.loading {
  position: fixed;
  z-index: 5000;
  font-size: 10px;
  width: 130px;
  height: 130px;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: #4c4c4c;
  box-sizing: border-box;
  line-height: 1.4;
  font-size: 17px;
  padding-top: 20px;
}
.loading_content {
  color: white;
}
.search {
  height: 44px;
  width: 100%;
margin-top: 50px;
  color: white;
}
.van-search {
  padding: 5px 12px;
}
</style>
