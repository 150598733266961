<template>
  <div>

    <div class="pick" @click="chooseHospital">

      <div style="padding-top: 5px; margin-right: 10px">
        <img src="../assets/images/icon_hospital.png" />
      </div>
      <div>选择医院</div>
    </div>
    <div class="choice">
      <div class="choice-left">已选医院</div>
      <div class="selectiveHospital">
        <div class="hospital" v-for="(item, i) in hospital" :key="i">
          <div v-if="item.choice" class="hospitalTag">
            {{ item.name }}
            <van-icon name="clear" @click="close(item.id)" />
          </div>
        </div>
      </div>
    </div>

    <van-form input-align="right">
      <div class="form-info">
        <van-field
          v-model="username"
          name="姓名"
          label="姓名"
          placeholder="请输入姓名"
        />
        <van-field
          v-model="IDNum"
          type="number"
          name="身份证号"
          label="身份证号"
          placeholder="请输入身份证号"
        />
        <van-field
          v-model="userNum"
          type="number"
          name="操作员号"
          label="操作员号"
          placeholder="请输入操作员号"
        />

        <div>
          <div>
            <van-cell
              class="roleBorder"
              is-link
              title="角色"
              :value="selectRole"
              @click="showRole = true"
            />
            <div class="borderBottom"></div>
          </div>
          <template>
            <van-action-sheet
              v-model="showRole"
              :actions="roleList"
              cancel-text="取消"
              title="角色"
              close-on-click-action
              @select="onRoleType"
            />
          </template>
        </div>

        <van-field
          v-model="phoneNum"
          center
          type="number"
          name="手机号"
          label="手机号"
          placeholder="请输入手机号"
        />
        <van-field
          v-model="sms"
          center
          label="短信验证码"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              style="color: #1989fa; background-color: white; border: none"
              @click="getSms"
              :disabled="disabled"
              >{{ code }}</van-button
            >
          </template>
        </van-field>
      </div>
      <div style="margin: 25px; background-color: #f3f3f3">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          class="btn"
          @click="onSubmit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      username: "",
      IDNum: "",
      userNum: "",
      phoneNum: "",
      selectRole: "",
      showRole: false,
      roleId: "",
      roleList: [],
      hospital: [],
      userData: {},
      sms: "",
      disabled: false,
      second: 61,
      code: "发送验证码",
      hos_id:[]
    };
  },
  created() {
    this.getRole();
    this.hos_id = this.$store.state.hos_id
    this.hospital = this.$store.state.hospital;
    console.log(this.hospital);
  },
  methods: {
    onSubmit() {
      console.log(this.hos_id);
      var that = this;
      if (!that.hos_id.length) {
        Toast("未选择医院");
        return;
      }
      if (!that.username) {
        Toast("未填写姓名");
        return;
      }
      if (!that.IDNum) {
        Toast("未填写身份证号");
        return;
      }
      if (!that.userNum) {
        Toast("未填写操作员号");
        return;
      }
      if (!that.roleId) {
        Toast("未选择角色");
        return;
      }
      if (!that.phoneNum) {
        Toast("未填写手机号");
        return;
      }
      if (!that.sms) {
        Toast("未填写验证码");
        return;
      }
      that.userData = {
        hos_id: that.hos_id,
        role_id: that.roleId,
        name: that.username,
        id_card: that.IDNum,
        oper_no: that.userNum,
        phone: that.phoneNum,
      };
      that.$http
        .post("v1/userInfo/register", {
          hos_id: that.hos_id,
          role_id: that.roleId,
          name: that.username,
          id_card: that.IDNum,
          oper_no: that.userNum,
          phone: that.phoneNum,
          code: that.sms,
        })
        .then((res) => {
          if (res.data.code == 200) {
            that.hospital.forEach((element) => {
              element.choice = false;
            });
            that.$store.dispatch("hospital", that.hospital);
            that.$router.push({
              path: "/complete",
              query: { userData: that.userData, page: 1 },
            });
          } else if (res.data.code == 400) {
            Toast(res.data.msg);
          } else {
            Toast("注册失败");
            console.log(res);
          }
        });
    },
    getRole() {
      var that = this;
      that.$http.post("v1/userInfo/getRole").then((res) => {
        console.log(res);
        that.roleList = res.data.data;
      });
    },
    onRoleType(e) {
      this.showRole = false;
      console.log(e.name);
      this.selectRole = e.name;
      this.roleId = e.id;
    },
    close(e) {
      console.log(this.hos_id);
      //  that.$store.dispatch("changeHos", that.hos_id);
      this.hospital.forEach((element) => {
        if (element.id == e) {
          element.choice = false;
        }
      });
      this.hos_id.splice(this.hos_id.indexOf(e), 1)
      this.$store.dispatch("hospital", this.hospital);
      console.log(this.hos_id);
    },
    chooseHospital() {
      this.$router.push("/chooseHospital");
    },
    getSms() {
      var that = this;
      if (!that.username) {
        Toast("未填写姓名");
        return;
      }
      if (!that.IDNum) {
        Toast("未填写身份证号");
        return;
      }
      if (!that.userNum) {
        Toast("未填写操作员号");
        return;
      }
      if (!that.roleId) {
        Toast("未选择角色");
        return;
      }
      if (!that.phoneNum) {
        Toast("未填写手机号");
        return;
      }
      if (that.phoneNum.length == 11 && that.phoneNum.charAt(0) == 1) {
        that.$http
          .post("v1/userInfo/sendSms", {
            phone: that.phoneNum,
          })
          .then((res) => {
            if (res.data.code == 200) {
              console.log(res);
              that.disabled = true;
              var time = setInterval(() => {
                if (that.second) {
                  that.second--;
                  that.code = this.second + "秒后重新发送";
                } else {
                  that.disabled = false;
                  that.second = 61;
                  that.code = "发送验证码";
                  clearInterval(time);
                }
              }, 1000);
            }
          });
      } else {
        Toast("请输入正确的手机号码");
      }
    },
  },
};
</script>
<style  lang="less" scoped>
.form-info {
  width: 90%;
  margin-left: 5%;
  background-color: white;
  border-radius: 15px;
  padding: 10px 0;
}
.pick {
  height: 60px;
  width: 92%;

  background: white;
  line-height: 60px;
  margin-left: 4%;
  border-radius: 15px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choice {
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

/deep/.van-field__label {
  color: #323232;
}
.selectiveHospital {
  display: flex;
  flex-wrap: wrap;
}
/* .hospital {
  background-color: white;
  padding: 3px;
  margin: 5px;
  border-radius: 2px;
  padding: 3px 3px;
  font-size: 14px;
  color: #A3A3A3;
} */
.choice-left {
  width: 30%;
  height: 60px;
  padding-left: 20px;
  padding-top: 5px;
}
.van-cell::after,
.borderBottom {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 3px solid #ebedf0;
}
.van-cell {
  height: 60px;
  align-items: center;
}
.btn {
  margin-top: 32px;
  font-size: 18px;
}
.hospitalTag {
  font-size: 12px;
  line-height: 16px;
  border-radius: 2px;
  align-items: center;
  display: inline-flex;
  -webkit-box-align: center;
  position: relative;
  background-color: #fff;
  padding: 2px 6px;
  margin-left: 10px;
  color: rgb(153, 153, 153);
  border-color: white;
}
.van-icon {
  color: #999999;
  background: white;
  font-size: 14px;
  margin-left: 9px;
}
</style>