import { render, staticRenderFns } from "./noData.vue?vue&type=template&id=33f1c8af&scoped=true&"
import script from "./noData.vue?vue&type=script&lang=js&"
export * from "./noData.vue?vue&type=script&lang=js&"
import style0 from "./noData.vue?vue&type=style&index=0&id=33f1c8af&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33f1c8af",
  null
  
)

export default component.exports